import React from "react";

import { API_END_POINT, MEDIA_URL } from "../../../constant/data";

import { fCurrencyMMK } from "../../../utils/FormatNumber";

import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { AddProduct } from "../../../redux/slice/cart";
import useLocales from "../../../hook/uselocales";

function ItemsCards(props) {
  const cart = useSelector((cart) => cart.cart);
  const dispatch = useDispatch();
  const { productList, loading, toggleSidebar } = props;
  const { translate } = useLocales();
  const navigate = useNavigate();

  const addProductToCart = (product) => {
    const hasDiscount = product.sale_price && product.sale_price !== "0";
    let cartProduct = {
      id: product.id,
      name: product.name,
      quantity: 1,
      price: hasDiscount ? product.sale_price : product.regular_price,
      image: product.images[0]?.image || "/media/default.png",
      variation_product: null,
      is_fulfilled: false,
      to_fulfill: "0",
      product: product.id,
      subtotal: parseFloat(
        hasDiscount ? product.sale_price : product.regular_price
      ).toFixed(0),
    };
    dispatch(AddProduct(cartProduct));
  };

  const handleDetailsClick = (product) => {
    navigate(`/product-details`, { state: product });
  };

  return (
    <div className="max-lg:flex-1 w-full lg:px-10 relative z-0">
      <div className="flex items-center w-full gap-10">
        <button className="xl:hidden" onClick={toggleSidebar}>
          <div className="flex items-center gap-1 mb-10">
            <h2 className="header-default max-lg:text-[18px]">Filter </h2>
            <div className="p-3 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#6E4345"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="50" y2="12"></line>
                <line x1="3" y1="6" x2="50" y2="6"></line>
                <line x1="3" y1="18" x2="50" y2="18"></line>
              </svg>
            </div>
          </div>
        </button>
        <h2 className="mb-10 header-default max-lg:text-[18px]">
          {translate("productYouMustTry")}
        </h2>
      </div>
      {loading ? (
        <div className=" w-full text-center">
          <ClipLoader color="#ed1b24" size={50} />
        </div>
      ) : productList.length === 0 ? (
        <p className=" text-3xl ">{translate("thereIsNoProduct")}</p>
      ) : (
        <div className="grid max-md:grid-cols-1 grid-cols-2 xl:grid-cols-3 gap-5 gap-y-10">
          {productList.map((product, index) => {
            let productImage;
            if (product.images) {
              product.images[0].image.includes("http")
                ? (productImage = product.images[0].image)
                : (productImage = MEDIA_URL + product.images[0].image);
            } else {
              product.image.includes("http")
                ? (productImage = product.image)
                : (productImage = MEDIA_URL + product.image);
            }

            let selected = false;
            cart.items.map((item, index) => {
              if (item.id === product.id) {
                selected = true;
              }
            });

            return (
              <div
                key={product.name}
                className="bg-[#f4f4f4] rounded-3xl py-6 px-2 max-xl:px-6 relative"
              >
                <img
                  src="/media/images/pep/web-img/simple-icons_happycow.png"
                  className="absolute left-4 top-4 max-lg:left-2 max-lg:top-2"
                  alt="happycow"
                />
                <div
                  onClick={() => handleDetailsClick(product)}
                  className="w-full flex justify-center"
                >
                  <img
                    src={productImage}
                    className="w-72 max-xl:w-full cursor-pointer"
                    alt="cream-milk"
                  />
                </div>
                <div className="flex justify-between items-center py-5 xl:px-10">
                  <div className="w-2/3">
                    <div
                      onClick={() => handleDetailsClick(product)}
                      className="cursor-pointer"
                    >
                      <h3 className="product-card-subheader">{product.name}</h3>
                    </div>

                    <p className="font-Cushy_W01 text-[#696969] text-sm py-4">
                      {product.sale_price
                        ? fCurrencyMMK(product.sale_price)
                        : fCurrencyMMK(product.regular_price)}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      // dispatch(AddProduct(product));
                      addProductToCart(product);
                    }}
                  >
                    <img
                      src={
                        selected
                          ? "/media/images/pep/web-img/ic_round-added-shopping-cart.png"
                          : "/media/images/pep/web-img/ic_round-add-shopping-cart.png"
                      }
                      style={{
                        backgroundColor: selected ? "" : "#ed1b24",
                        width: selected ? "60px" : "",
                      }}
                      className="rounded-full p-3 lg:p-2 w-12 cursor-pointer"
                      alt="shopping-cart"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ItemsCards;
