import React from "react";
import useLocales from "../../../hook/uselocales";

function Footer() {
  const { translate } = useLocales();
  return (
    <footer className="bg-[var(--primary-color)] text-white px-20 py-10 product-footer">
      <div className="flex justify-between items-center">
        <img
          src="/media/images/pep/web-img/logo1-white.png"
          className="w-[100px] lg:w-[130px]"
          alt="pep-logo"
        />
        <div className="flex justify-center items-center">
          <div className="flex flex-col items-center gap-4 p-4">
            <p className="font-Cushy_W01">{translate("followUsOn")}</p>
            <div className="flex gap-3">
              <img
                src="/media/images/pep/web-img/Facebook.png"
                className="cursor-pointer"
                alt="facebook"
              />
              <img
                src="/media/images/pep/web-img/YouTube.png"
                className="cursor-pointer"
                alt="youtube"
              />
            </div>
          </div>
          <img
            src="/media/images/pep/web-img/2c2c92de0198469b040df2b30e91894c.gif"
            className="w-32"
            alt="gif"
          />
        </div>
      </div>

      <hr className="my-10" />

      <div className="flex justify-between items-center">
        <p className="font-Cushy_W01 text-white">
          {translate("Red Horse Company Limited")}
        </p>
        <div className="font-Cushy_W01 flex justify-center items-center gap-7">
          <div className="hidden lg:flex justify-center items-center gap-7 footer-menu">
            <a href="https://dev.360-digital.net/pep/about-us/">
              {translate("aboutUs")}
            </a>
            <a href="https://dev.360-digital.net/pep/product/">
              {translate("products")}
            </a>
            <a href="https://dev.360-digital.net/pep/blogs/">
              {translate("blogs")}
            </a>
            <a href="https://dev.360-digital.net/pep/careers/">
              {translate("careers")}
            </a>
          </div>
          <a
            href="https://dev.360-digital.net/pep/contact-us/"
            className="lg:text-[36px] sm:text-[20px] font-Annifont"
          >
            {translate("contactUs")}
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
