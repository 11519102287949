import React from "react";
import NavBar from "../../components/NavBar";
import Footer from "./components/Footer";
import ProductDetailsSection from "./components/ProductDetailsSection";

const ProductDetails = () => {
  return (
    <section>
      <NavBar />
      <ProductDetailsSection />
      <Footer />
    </section>
  );
};

export default ProductDetails;
