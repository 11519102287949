import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AddProduct } from "../../../redux/slice/cart";
import { useNavigate, useLocation } from "react-router-dom";
import { MEDIA_URL } from "../../../constant/data";
import { fCurrencyMMK } from "../../../utils/FormatNumber";

const ProductDetailsSection = () => {
  const cart = useSelector((cart) => cart.cart);
  const dispatch = useDispatch();
  const location = useLocation();
  const product = location.state;

  const [mainImage, setMainImage] = useState(
    product.images && product.images[0]?.image
      ? product.images[0].image.startsWith("http")
        ? product.images[0].image
        : MEDIA_URL + product.images[0].image
      : "/media/default.png"
  );
  const [value, setValue] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const addProductToCart = (product) => {
    const hasDiscount = product.sale_price && product.sale_price !== "0";
    const price = hasDiscount ? product.sale_price : product.regular_price;
    let cartProduct = {
      id: product.id,
      name: product.name,
      quantity: value,
      price: price,
      image: product.images[0]?.image || "/media/default.png",
      variation_product: null,
      is_fulfilled: false,
      to_fulfill: "0",
      product: product.id,
      // subtotal: parseFloat(
      //   hasDiscount ? product.sale_price : product.regular_price
      // ).toFixed(0),
      subtotal: (parseFloat(price) * value).toFixed(0),
    };
    dispatch(AddProduct(cartProduct));
  };

  const handleIncrement = () => {
    setValue((prevValue) => prevValue + 1);
  };

  const handleDecrement = () => {
    setValue((prevValue) => (prevValue > 1 ? prevValue - 1 : 1));
  };

  const handleInputChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 1) {
      setValue(newValue);
    }
  };

  return (
    <section className="xl:py-20 xl:px-16 max-xl:py-20 max-xl:px-2 relative">
      <div className="max-w-[1440px] lg:m-auto flex max-xl:flex-col relative gap-8">
        <div className="w-full flex max-md:flex-col gap-4">
          <div className="w-full max-sm:w-full relative">
            <div
              onClick={openModal}
              className="absolute top-4 right-4 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M10.6667 26.6666H5.33337M5.33337 26.6666V21.3333M5.33337 26.6666L13.3334 18.6666M21.3334 5.33325H26.6667M26.6667 5.33325V10.6666M26.6667 5.33325L18.6667 13.3333"
                  stroke="#1A1A1A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <img
              src={mainImage}
              alt={product.name}
              className="w-full object-contain border border-gray-300 rounded-md"
            />
          </div>
          <div className="w-[40%] flex flex-col gap-2">
            {product.images &&
              product.images.slice(0, 3).map((productImg, index) => {
                const imageUrl = productImg.image.startsWith("http")
                  ? productImg.image
                  : MEDIA_URL + productImg.image;

                return (
                  <img
                    key={index}
                    src={imageUrl}
                    alt={`${product.name} - Thumbnail ${index + 1}`}
                    className={`w-40 h-40 max-sm:w-24 max-sm:h-24 border rounded-sm cursor-pointer ${
                      mainImage === imageUrl
                        ? "border-gray-500"
                        : "border-gray-300"
                    }`}
                    onClick={() => setMainImage(imageUrl)}
                  />
                );
              })}
          </div>
        </div>

        <div className="w-full p-4">
          <h1 className="text-2xl mb-8 font-Cushy text-[var(--text-color)]">
            {product.name}
          </h1>
          <p className="text-2xl font-Cushy text-[var(--primary-color)] mb-8">
            {product.sale_price
              ? fCurrencyMMK(product.sale_price)
              : fCurrencyMMK(product.regular_price)}
          </p>

          <h2 className="text-xl font-Cushy text-[var(--text-color)] mb-3">
            Description
          </h2>
          <p
            className="font-Cushy_W01 text-[var(--text-color)] mb-8"
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
          <div className="w-full flex items-center gap-8">
            <div className="w-1/2">
              <div className="number-input">
                <button type="button" onClick={handleDecrement}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="2"
                    viewBox="0 0 14 2"
                    fill="none"
                  >
                    <path d="M14 2H0V0H14V2Z" fill="#696969" />
                  </svg>
                </button>
                <input
                  value={value}
                  type="text"
                  onChange={handleInputChange}
                  className="text-2xl font-Cushy text-[var(--text-color)]"
                />
                <button type="button" onClick={handleIncrement}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 12.998H13V18.998H11V12.998H5V10.998H11V4.99805H13V10.998H19V12.998Z"
                      fill="#1A1A1A"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <button
              className="w-full bg-[var(--primary-color)] font-Cushy text-white py-3 rounded-lg hover:bg-red-800"
              onClick={() => addProductToCart(product)}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>

      {/* Modal  */}
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={closeModal}
        >
          <div className="relative w-full h-full flex items-center justify-center max-md:p-4">
            <img
              src={mainImage}
              alt={product.name}
              className="max-w-full max-h-full object-contain"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default ProductDetailsSection;
