import { useDispatch, useSelector } from "react-redux";
import NavBar from "../components/NavBar";
import { useEffect, useState } from "react";
import { MEDIA_URL } from "../constant/data";
import { fCurrencyMMK } from "../utils/FormatNumber";
import { useNavigate } from "react-router-dom";
import { FillProduct } from "../redux/slice/cart";
import useLocales from "../hook/uselocales";

function AddToCart() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const dispath = useDispatch();
  const selectedProduct = useSelector((cart) => cart.cart.items);
  const [productData, setProductData] = useState(selectedProduct || []);
  const [totalPrice, setTotalPrice] = useState("");

  useEffect(() => {
    let totalAmountArrays = [];
    productData.map((product, index) => {
      totalAmountArrays.push(product.subtotal);
    });
    setTotalPrice(
      totalAmountArrays.reduce(
        (partialSum, a) => Number(partialSum) + Number(a),
        0
      )
    );
  }, [productData]);

  return (
    <>
      <NavBar />
      <section className="p-10">
        <div className="w-full bg-white rounded-3xl py-4 px-6 cart-container">
          <h2 className="header-default max-sm:text-[22px]">
            {translate("shoppingCart")}
          </h2>
          {productData
            ? productData.map((product, index) => {
                let productImage = product.image.includes("http")
                  ? product.image
                  : MEDIA_URL + product.image;
                // let subtotal = product.quantity * product.price;
                return (
                  <div
                    key={product.name + index}
                    className="flex flex-wrap justify-between items-center py-8 border-b-2 border-[#0000001A]"
                  >
                    <div className="flex flex-wrap justify-center items-center ">
                      <img
                        src={productImage}
                        alt="product-img"
                        width={"80px"}
                        height="auto"
                      />
                      <p className="font-Cushy_W01 text-[var(--text-color)]">
                        {product.name}
                      </p>
                    </div>
                    <div className="flex w-1/3 justify-between align-middle">
                      <div className="flex flex-wrap justify-between items-center gap-3 py-2 px-4 rounded-2xl border border-[var(--text-color)]">
                        <img
                          onClick={() => {
                            setProductData((preState) => {
                              let newState = JSON.parse(
                                JSON.stringify(preState)
                              );
                              if (newState[index].quantity !== 1) {
                                newState[index].quantity =
                                  newState[index].quantity - 1;
                              }
                              newState[index].subtotal =
                                newState[index].price *
                                newState[index].quantity;
                              return newState;
                            });
                          }}
                          src="/media/images/pep/web-img/minus.png"
                          className="cursor-pointer"
                          alt="minus"
                        />
                        <p className="font-Cushy_W01 text-[var(--text-color)]">
                          {productData[index].quantity}
                        </p>
                        <img
                          src="/media/images/pep/web-img/plus.png"
                          className="cursor-pointer"
                          alt="plus"
                          onClick={() => {
                            setProductData((preState) => {
                              let newState = JSON.parse(
                                JSON.stringify(preState)
                              );
                              newState[index].quantity =
                                Number(newState[index].quantity) + 1;
                              newState[index].subtotal =
                                newState[index].price *
                                newState[index].quantity;
                              return newState;
                            });
                          }}
                        />
                      </div>
                      <div>
                        <h3 className="font-[Cushy] text-[var(--text-color)]">
                          {fCurrencyMMK(product.subtotal)}
                        </h3>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
        <div className="w-full text-[var(--text-color)] font-Cushy_W01 flex flex-wrap justify-end py-10 px-6">
          <div className="w-1/3">
            {/* <div className="border-b border-[#696969] py-10">
              <div className="flex flex-wrap justify-between items-center">
                <p>Total Price</p>
                <p>{fCurrencyMMK(totalPrice)} MMK</p>
              </div>
              <div className="flex flex-wrap justify-between items-center my-8">
                <p>Tax</p>
                <p className="font-Poppins">0 %</p>
              </div>
              <div className="flex flex-wrap justify-between items-center">
                <p>Delivery Fees</p>
                <p>Cash</p>
              </div>
            </div> */}
            <div className="flex flex-wrap justify-between items-center py-10">
              <p>{translate("totalAmount")}</p>
              <p className="text-2xl text-[var(--primary-color)] font-[Cushy]">
                {fCurrencyMMK(totalPrice)}
              </p>
            </div>
          </div>
        </div>
        <div className="flex max-sm:flex-wrap justify-between items-center gap-y-4 py-10 lg:px-6">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="bg-white border border-[var(--primary-color)] text-[var(--primary-color)] max-sm:text-[12px] rounded-lg max-sm:px-4 py-[0.6rem] px-8 md:py-4 md:px-16 shopping-btn"
          >
            {translate("continueShopping")}
          </button>
          <button
            onClick={() => {
              dispath(FillProduct(productData));
              localStorage.setItem("product", JSON.stringify(productData));
              navigate("/customer-details");
            }}
            className="bg-[var(--primary-color)] border-[var(--primary-color)] max-sm:text-[12px] rounded-lg max-sm:px-14 py-[0.65rem] px-16 md:py-4 md:px-24 text-white checkout-btn"
          >
            {translate("checkout")}
          </button>
        </div>
      </section>
    </>
  );
}

export default AddToCart;
