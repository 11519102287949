import Product from "./pages/product/Product";
import AccountDetails from "./pages/account/components/AccountDetails";
import OrderHistory from "./pages/account/components/OrderHistory";
import AddToCart from "./pages/AddToCart";
import LoginForm from "./components/LoginForm";
import SignUpForm from "./components/SignUpForm";
import ConfirmOrder from "./pages/ConfirmOrder";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OrderDetails from "./pages/checkoutOption/OrderDetails";
import BillingDetail from "./pages/billingDetail/BillingDetail";
import ProductDetails from "./pages/product/ProductDetails";
import { setOldToken } from "./components/auth/setOldToken";
import axios from "axios";
import { API_END_POINT } from "./constant/data";

function App() {
  const getStore = async () => {
    const response = await axios.get(`${API_END_POINT}web/advance/store/`);
    localStorage.setItem("store", JSON.stringify(response.data.settings));
  };

  useEffect(() => {
    getStore();
    setOldToken();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Product />} />
        <Route path="/add-to-cart" element={<AddToCart />} />
        <Route path="/customer-details" element={<OrderDetails />} />
        <Route path="/payment-details" element={<BillingDetail />} />
        <Route path="/confirm-order" element={<ConfirmOrder />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/sign-up" element={<SignUpForm />} />
        <Route path="/account-detail" element={<AccountDetails />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/product-details" element={<ProductDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
